import { numWithCommas, log } from './helpers'
import { fetchEcoRebates } from './queries'
import parseIncentives from './parseIncentives'
import { defaults } from '../components/page content/campaigns/_defaults'

/*
EcoRebates Tracking:
uiContext=PLP
PLP: product list
PDP: product details
*/

export const findEcoRebates = async (zip, preferredProduct, includeLmiRebates) => {
    const rawRebates = await fetchEcoRebates(zip, preferredProduct)
    const parsedIncentives = parseIncentives(rawRebates.productRebateDetails, [preferredProduct])

    // omit incentives for LMI households unless otherwise specified
    const finalIncentives = includeLmiRebates ? parsedIncentives : parsedIncentives.filter((incentive) => !incentive.isLmiRebate)

    return finalIncentives
}

export const findInstantRebates = (userZip, state, staticCampaign, pageCampaignId) => {
    let foundRebateZips,
        foundRebateAmount,
        foundRebateTerms,
        foundRebateCampaign,
        finalRebate = { adminName: 'Mitsubishi Electric', type: 'Instant Rebate' }
    // if state overlaps multiple BU regions (like New York), search regions for zip code, then set rebate data from REGION level
    if (state.regions) {
        let foundRegion = state.regions.find((region) => region.zips.includes('all') || region.zips.includes(userZip))

        // no rebates available for that zip, so break out of function
        if (!foundRegion) return log(`no region with instant rebate in ${userZip}`)

        foundRebateZips = foundRegion.zips
        foundRebateCampaign = foundRegion.bu
        foundRebateAmount = foundRegion.amount
        foundRebateTerms = foundRegion.footnote
        // otherwise set rebate data from STATE level
    } else {
        console.log('update instant rebate data')
        foundRebateZips = state.zips
        foundRebateCampaign = state.bu
        foundRebateAmount = state.amount
        foundRebateTerms = state.footnote
    }

    const foundRebateCampaignId = foundRebateCampaign.leadCampaignId
    log('static campaign?', staticCampaign ? 'YES' : 'NO')

    // ONLY reassign campaign ID if:
    // --> page's campaign is NOT set to static, OR
    // --> found rebate's campaign is different from page's current campaign
    if (!staticCampaign && foundRebateCampaignId !== pageCampaignId) {
        log('found local campaign:', foundRebateCampaign)
        if (foundRebateCampaign.noOutsideLeads) {
            log(`${foundRebateCampaign.bu} requested no outside leads, reassign to Comfort Master:`, defaults.leadCampaignId)
            finalRebate.campaignId = defaults.leadCampaignId
        } else {
            log(`reassigning lead to local ${foundRebateCampaign.bu} campaign:`, foundRebateCampaign.leadCampaignId)
            finalRebate.campaignId = foundRebateCampaign.leadCampaignId
        }
    }

    // search state zips field for 'all' or user zip
    if (foundRebateZips.includes('all') || foundRebateZips.includes(userZip)) {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const start = foundRebateCampaign.startDate
        const end = foundRebateCampaign.endDate

        const startDateString = `${months[start.getMonth()]} ${start.getDate()}, ${start.getFullYear()}`
        const endDateString = `${months[end.getMonth()]} ${end.getDate()}, ${end.getFullYear()}`

        finalRebate.amount = foundRebateCampaign.isRunning ? foundRebateAmount : 0
        finalRebate.purchaseDates = { buyAfter: startDateString, buyBefore: endDateString }
        finalRebate.details = foundRebateTerms
        finalRebate.amount > 0 && (finalRebate.campaignId = foundRebateCampaign.leadCampaignId)
    } else {
        log(`no participating zips found`)
        finalRebate.amount = 0
    }
    // console.log('final instant Rebate:', finalRebate)
    return finalRebate
}
