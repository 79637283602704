import campaign from './campaigns/new-york'
import altText from './static-content/altText'
import indoorSolutions from './static-content/indoorSolutions'
import subscribe from './static-content/subscribe'
import warranty from './static-content/contentBoxWarranty'
import financing from './static-content/contentBoxFinancing'
import download from './static-content/download'
import mitsubishiSystems from './static-content/mitsubishiSystems'
import ira25C from './static-content/ira-25c'

const buPhone = '833-977-0252'

const hero = campaign()

const sections = [
    {
        id: 'how-heat-pumps-work',
        class: 'two-column-img-right',
        title: 'How it works',
        blurb: 'Heating and cooling mode',
        detailsText:
            'In heating mode, the outdoor heat pump absorbs heat energy from the outside air and transfers it to the inside unit via refrigerant. Even in freezing temperatures, there is heat in the air to be absorbed and delivered into the indoor spaces. This heating process is much cleaner and better for the planet than burning fossil fuels and results in zero emissions.',
        imgSrc: '/images/how-heat-pumps-work-video-poster-summer.webp',
        imgAlt: `${altText} video poster for 4 Reasons To Choose Mitsubishi Electric Heat Pumps`,
        ctaText: 'Watch Video',
        ctaUrl: 'form-section',
        videoSrc: 'oETVJ7Nx9g0',
        backgroundCol: 'transparent',
    },
    mitsubishiSystems({ color: 'blue' }),
    indoorSolutions({ color: 'transparent', ctaText: 'Request a Quote', hasNavAnchor: true }),
    ira25C({ color: 'orange' }),
    download({}),
    {
        id: 'Support',
        class: 'three-column-cards-w-image',
        title: 'The Resources You Need',
        contentBoxes: [
            {
                iconSrc: 'power-bill-invoice',
                iconAlt: `${altText} calculate the cost of HVAC`,
                blurb: 'HVAC Cost Calculator',
                detailsText: `Use ConEdison's helpful HVAC cost calculator tool to compare system types.`,
                ctaText: 'Try It Out',
                ctaUrl: 'https://stage.apogee.net/mvc/home/hes/land/el?utilityname=coned&spc=hcc',
            },
            warranty,
            financing,
        ],
        backgroundCol: 'transparent',
    },
    subscribe({ campaignId: hero.subscriberCampaignId }),
]

const header = {
    pageTitle: 'Save Thousands Through New York State Utility Providers on Qualifying Mitsubishi Electric HVAC Systems',
    pageDesc:
        "Stay comfortable year round with Mitsubishi Electric's single-zone and Hyper-Heating INVERTER® heat pump systems, where exceptional comfort meets energy efficiency. Enter your zip code now to unlock local incentives!",
    pageImg: hero.backgroundSrc,
    phone: buPhone,
    ctaText: 'Get Started',
    ctaUrl: 'form-section',
    pageSections: sections.map((section) => section),
}

export { header, hero, sections }
