import getCampaignData from '../../../lib/getCampaignData'
import { defaults } from './_defaults'

export default function campaign() {
    const start = '9/9/2024'
    const end = '11/4/2024'
    const amount = 0

    const data = {
        // defaults
        bu: 'mabu',
        defaultAmount: 0,
        defaultCampaignId: defaults.leadCampaignId,
        defaultSubscriberId: '7013q000001kmXZAAY',
        defaultHero: 'rebate',
        defaultCtaText: '',
        defaultImage: '/images/lifestyle/interior/cbu-spring.webp',
        defaultImageAlign: 'top',
        defaultTitle: defaults.title,
        defaultBlurb: defaults.blurb,
        defaultDetails: defaults.detailsText,
        defaultFootnote: '',
        utilityProduct: defaults.utilityProduct,
        whatsNext: defaults.whatsNext,
        // ME_Residential_MABU_Fall_2024_MASTER
        start: start,
        end: end,
        campaignAmount: amount,
        campaignId: '701Pd00000EGhybIAD',
        campaignHero: 'rebate',
        // campaignCtaText: 'Get Started',
        campaignImage: '/images/lifestyle/interior/cbu-spring.webp',
        campaignImageAlign: 'center',
        campaignTitle: defaults.title,
        campaignBlurb: 'Shift Seasons In Comfort',
        campaignDetails:
            'Mitsubishi Electric heating and air conditioning systems provide personalized, energy-efficient comfort no matter the weather outside, and qualify for rebates from many local electric utility providers.',
        campaignFootnote: `Instant rebate of $${amount} is valid only within NJ, PA, DE, MD, and select areas in NY, WV, and VA and varies based on system purchased. Qualifying system must be purchased from and installed by an eligible contractor in an owner-occupied residence between ${start} and ${end}.`,
        hideContractorNumSelector: true,
        hideTimeframeSelector: false,
        hideContactMethodSelector: false,
        hideLocationSelectors: false,
        hideSystemSelector: false,
        hideCommunicationsSelector: false,
        hideDownArrow: false,
    }

    const finalData = getCampaignData(data)

    return finalData
}
