import { useState } from 'react'
import Modal from 'react-modal'
import Icon from './Icon'
import Button from './Button'

export default function ModalButton({ children, mini, text = 'info' }) {
    const [modalIsOpen, setIsOpen] = useState(false)

    const openModal = () => setIsOpen(true)
    const afterOpenModal = () => {}
    const closeModal = () => setIsOpen(false)
    return (
        <>
            <Button mini={mini} type={'button'} text={text} onClick={openModal} />
            <Modal
                className="modal glass-panel financing-modal"
                overlayClassName="modal-overlay"
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                contentLabel="Modal"
            >
                <Icon id="x" alt="close button" onClick={closeModal}>
                    X
                </Icon>
                {children}
            </Modal>
        </>
    )
}
